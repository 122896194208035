import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import MenuToggleButton from "./MenuToggleButton";

function MenuItem({ item }) {
  const [expandedMenu, setExpandedMenu] = useState({});

  const location = useLocation();

  useEffect(() => {
    if (item.children?.length > 0) {
      const searchedItem = item?.children.find(
        (child) => child.destination === location.pathname
      );

      if (searchedItem) {
        setExpandedMenu((menu) => {
          const updatedMenu = { ...menu };
          updatedMenu[`${item.id}`] = true;
          return updatedMenu;
        });
      }
    }
  }, []);

  const toggleExpandedMenu = (id) => {
    setExpandedMenu((menu) => {
      const current = menu[`${id}`];
      const updatedMenu = { ...menu };
      updatedMenu[`${id}`] = !current;
      return updatedMenu;
    });
  };

  return (
    <li className="items-center" key={item.id}>
      <div className="flex flex-row">
        <NavLink
          to={item.destination}
          className="flex gap-4 text-sm uppercase py-3 items-center w-full"
        >
          {item.Icon}

          <span
            className={`uppercase w-full  ${
              location.pathname === item.destination
                ? "text-gray-100 font-semibold hover:text-white"
                : "text-gray-300 hover:text-white"
            }`}
          >
            {item.title}
          </span>
        </NavLink>

        {item.children !== undefined ? (
          <div className="flex items-center">
            <MenuToggleButton
              onClick={() => toggleExpandedMenu(item.id)}
              isExpanded={expandedMenu[`${item.id}`] === true}
            />
          </div>
        ) : null}
      </div>

      {item.children !== undefined ? (
        <ul
          className={`ml-4 ${
            expandedMenu[`${item.id}`] === true
              ? "block duration-150"
              : "hidden duration-150"
          }`}
        >
          {item.children?.map((childItem) => (
            <MenuItem item={childItem} key={childItem.id} />
          ))}
        </ul>
      ) : null}
    </li>
  );
}

export default MenuItem;
