import Home from "../pages/admin/home/Home";
import Login from "../pages/login/Login";
import News from "../pages/admin/news/News";
import OfferProduct from "../pages/admin/offer-product/OfferProduct";
import User from "../pages/admin/user/User";
import Logout from "../pages/login/Logout";
import AppLayout from "./AppLayout";
import NotFound from "../pages/404/NotFound";
import SendPushNotification from "../pages/admin/push-notification/SendPushNotification";
import OfferProductSortOrder from "../pages/admin/offer-product/OfferProductSortOrder";
import CouponCampaign from "../pages/admin/coupons/campaign/CouponCampaign";
import CouponCampaignDetails from "../pages/admin/coupons/campaign/CouponCampaignDetails";
import CouponPrizeUsers from "../pages/admin/coupons/prizes/CouponPrizeUsers";
import CouponPrizeAssignUsers from "../pages/admin/coupons/prizes/CouponPrizeAssignUsers";
import UserCoupon from "../pages/admin/user/UserCoupon";

export const routes = [
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/news",
        element: <News />,
        children: [
          {
            path: ":status?/page?/:page?",
            element: <News />,
          },
        ],
      },
      {
        path: "/offer-products",
        children: [
          {
            path: "",
            element: <OfferProduct />,
          },
          {
            path: "sort-order",
            element: <OfferProductSortOrder />,
          },
          {
            path: ":status?/page?/:page?",
            element: <OfferProduct />,
          },
        ],
      },
      {
        path: "/coupon-campaign",
        children: [
          {
            path: "",
            element: <CouponCampaign />,
          },
          {
            path: "create",
            element: <CouponCampaign />,
          },
          {
            path: ":campaignId/edit",
            element: <CouponCampaign />,
          },
          {
            path: ":campaignId/delete",
            element: <CouponCampaign />,
          },
          {
            path: ":campaignId/download",
            element: <CouponCampaign />,
          },
          {
            path: ":id/prize/create",
            element: <CouponCampaignDetails />,
          },
          {
            path: ":id/prize/:prizeId/edit",
            element: <CouponCampaignDetails />,
          },
          {
            path: ":id/prize/:prizeId/delete",
            element: <CouponCampaignDetails />,
          },
          {
            path: ":id",
            element: <CouponCampaignDetails />,
          },
          {
            path: ":status?/page?/:page?",
            element: <CouponCampaign />,
          },
        ],
      },
      {
        path: "/coupon-prize",
        children: [
          {
            path: "",
            element: <CouponCampaign />,
          },
          {
            path: "create",
            element: <CouponCampaign />,
          },
          {
            path: ":id/users/page?/:page?",
            element: <CouponPrizeUsers />,
          },
          {
            path: ":id/users/add",
            element: <CouponPrizeAssignUsers />,
          },
          {
            path: ":id/users/edit",
            element: <CouponPrizeAssignUsers />,
          },
        ],
      },
      {
        path: "/users",
        children: [
          {
            path: "",
            element: <User />,
          },
          {
            path: "page/:page",
            element: <User />,
          },
          {
            path: "download",
            element: <User />,
          },
          {
            path: ":id/coupons",
            element: <UserCoupon />,
          },
        ],
      },
      {
        path: "/push-notification",
        element: <SendPushNotification />,
      },
      {
        path: "/logout",
        element: <Logout />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];
